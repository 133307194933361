<mspot-heading-wrapper>
  <div class="flex items-center sm:flex-col sm:items-start">
    <h1 class="heading1">
      <ng-content></ng-content>
    </h1>
    <div class="ml-2 sm:ml-0 sm:mt-1">
      <mspot-help-link [link]="helpLink">{{ helpLinkText }}</mspot-help-link>
    </div>
  </div>
</mspot-heading-wrapper>
