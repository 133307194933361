import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'mspot-heading-wrapper',
  template: ` <ng-content></ng-content> `,
  styles: [],
  host: {
    class: 'pt-4 pb-2 block px-4',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
  standalone: true,
})
export class HeadingWrapperComponent {
  @Input() euqalizePageContentX = false;

  @HostBinding('class')
  get padding() {
    return this.euqalizePageContentX
      ? 'page-content-x-4 equalize-page-content-x'
      : '';
  }
}
