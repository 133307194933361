import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[mspot-menu-button]',
  templateUrl: './menu-button.component.html',
  styles: [],
  host: {
    type: 'button',
    class:
      'w-8 h-8 flex justify-center items-center rounded-full active:bg-gray-100 hover:bg-gray-100 text-gray-600',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuButtonComponent {}
