import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[mspotTableActionButton]',
  templateUrl: './table-action-button.component.html',
  styles: [],
  host: {
    type: 'button',
    class:
      'w-8 h-8 flex justify-center icon-btn taauto items-center rounded-full active:bg-gray-100 hover:bg-gray-100 ease-in duration-150',
  },
  standalone: true,
  imports: [CommonModule, NgIconsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableActionButtonComponent {
  @Input('mspotTableActionButton') icon = '';
  @Input() iconName = '';
}
