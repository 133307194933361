import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { mspotLinkType } from '@memberspot/frontend/shared/ui/elements';

@Component({
  selector: 'mspot-header-helplink',
  templateUrl: './header-helplink.component.html',
  styles: [],
  host: {
    class: 'block',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderHelplinkComponent {
  @Input() helpLink!: mspotLinkType;
  @Input() helpLinkText!: string;
}
