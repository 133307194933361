import { Directive } from '@angular/core';

@Directive({
  selector: '[mspotNoDataMessage]',
  host: {
    class: 'flex flex-col justify-center items-center text-sm text-gray-500',
  },
})
export class NoDataMessageDirective {
  constructor() {}
}
